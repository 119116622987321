@import "mixins-and-variables";

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  display: flex;
  background: $gray-1;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Calibre Test';
  color: $gray-8;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
}

#root {
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  padding-top: 30px;

  @include for-size(tablet-up) {
    align-items: center;
    padding-top: 0;
  }
}

h1,
h2 {
  font-weight: 300;
  font-family: 'Calibre Test';
  margin-bottom: 30px;
}

h1 {
  font-size: 30px;

  @include for-size(tablet-up) {
    font-size: 42px;
  }
}

h2 {
  font-size: 26px;

  @include for-size(tablet-up) {
    font-size: 30px;
  }
}

a,
a:visited {
  text-decoration: none;
  color: $purple;
}

a:hover {
  color: $purple-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

