
$gray-1: #FAFAFA;
$gray-2: #F5F5F5;
$gray-3: #E1E1E1;
$gray-4: #BDBDBD;
$gray-5: #999999;
$gray-6: #757575;
$gray-7: #666666;
$gray-8: #333333;


$blue-lighter: #bcd1f9;
$blue-light: #70E1FF;
$blue: #08CBFE;
$blue-dark: #03AFDC;

$purple-lighter: #d4d3f6;
$purple-light: #7F8CFF;
$purple: #606EDA;
$purple-dark: #293C89;

$error: #ff1744;
$errorLight: #ff6e7f;
$errorLightest: #ffbecb;


$success: #ff1744;
$successLight: #2acf7c;

$input-border: #DDDDDD;

:export {
  gray1: $gray-1;
  gray2: $gray-2;
  gray3: $gray-3;
  gray4: $gray-4;
  gray5: $gray-5;
  gray6: $gray-6;
  gray7: $gray-7;
  gray8: $gray-8;

  blueLighter: $blue-lighter;
  blueLight: $blue-light;
  blue: $blue;
  blueDark: $blue-dark;

  purpleLighter: $purple-lighter;
  purpleLight: $purple-light;
  purple: $purple;
  purpleDark: $purple-dark;

  error: $error;
  errorLight: $errorLight;
  errorLightest: $errorLightest;

  success: $success;
  successLight: $successLight;

  inputBorder: $input-border;
}


@mixin for-size($size) {
  @if $size == tablet-up {
    @media (min-width: 580px) { @content; }

  } @else if $size == desktop-up {
    @media (min-width: 1080px) { @content; }

  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) { @content; }

  }
}
